import request from '@/utils/request'


// 查询新-用户邀请码记录列表
export function listInvitationRecord(query) {
  return request({
    url: '/cigarette/user-invitation-record/list',
    method: 'get',
    params: query
  })
}

// 查询新-用户邀请码记录分页
export function pageInvitationRecord(query) {
  return request({
    url: '/cigarette/user-invitation-record/page',
    method: 'get',
    params: query
  })
}

// 查询新-用户邀请码记录详细
export function getInvitationRecord(data) {
  return request({
    url: '/cigarette/user-invitation-record/detail',
    method: 'get',
    params: data
  })
}

// 新增新-用户邀请码记录
export function addInvitationRecord(data) {
  return request({
    url: '/cigarette/user-invitation-record/add',
    method: 'post',
    data: data
  })
}

// 修改新-用户邀请码记录
export function updateInvitationRecord(data) {
  return request({
    url: '/cigarette/user-invitation-record/edit',
    method: 'post',
    data: data
  })
}

// 删除新-用户邀请码记录
export function delInvitationRecord(data) {
  return request({
    url: '/cigarette/user-invitation-record/delete',
    method: 'post',
    data: data
  })
}
